"use client";
import Datadog from "@/datadog/datadog-init";
import React from "react";

interface ErrorBoundaryProps {
  children: React.ReactNode;
  from?: string;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error: Error | null;
}

export default class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error: error };
  }

  componentDidCatch(error: Error) {
    const message = `error boundary from: ${this.props.from}`;
    console.error(message, error);
    Datadog.addError(error, {
      message,
      from: this.props.from,
    });
  }

  render() {
    if (this.state.hasError) {
      return process.env.NODE_ENV === "development" ? (
        <p>{this.state.error?.message}</p>
      ) : (
        <h1>Sorry... There was an error</h1>
      );
    }

    return this.props.children;
  }
}
