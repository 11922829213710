import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/usr/src/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["ChatbotInitializer"] */ "/usr/src/app/src/app/_components/Chatbot/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/src/app/_components/ErrorBoundary/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["KeycloakInitializer"] */ "/usr/src/app/src/app/_components/Keycloak/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/src/app/_components/Segment/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/src/app/_components/Snowplow/index.tsx");
;
import(/* webpackMode: "eager" */ "/usr/src/app/src/app/global.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/src/app/lib/registry.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/src/datadog/initializer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/src/hotjar/initializer.tsx");
