"use client";

import { useEffect } from "react";
import { useParams, usePathname, useSearchParams } from "next/navigation";
import { segmentAnalytics } from "@/app/lib/segment";
import Cookies from "js-cookie";
import useSnowplow from "@/app/_hooks/useSnowplow";

enum PAGE_TYPES {
  home = "home",
  search = "search",
  article = "article",
  all_articles = "all articles",
  category = "category",
  not_found = "not found",
  contact = "contact",
  unmapped = "unmapped",
}

// TODO: pass this analytical data to maestro
export default function Analytics() {
  const pathname = usePathname();
  const params = useParams<{ region: string; readableId?: string }>();
  const searchParams = useSearchParams();

  const { eventDispatcher } = useSnowplow();

  const getPageType = (path: string) => {
    const pathLength = path.split("/").length;

    if (pathLength === 2) {
      return PAGE_TYPES.home;
    }
    if (path.includes("/search-results")) {
      return PAGE_TYPES.search;
    }
    if (pathLength === 3 && path.includes("/articles")) {
      return PAGE_TYPES.all_articles;
    }
    if (pathLength === 4 && path.includes("/articles/")) {
      return PAGE_TYPES.article;
    }
    if (path.includes("/category")) {
      return PAGE_TYPES.category;
    }
    if (path.includes("/contact")) {
      return PAGE_TYPES.contact;
    }
    if (path.includes("/not-found")) {
      return PAGE_TYPES.not_found;
    }
    return PAGE_TYPES.unmapped;
  };

  const getArticleReadableId = (pageType: PAGE_TYPES) => {
    if (pageType === PAGE_TYPES.article) {
      return params.readableId ?? null;
    }

    return null;
  };

  const getArticleTitleFromHTML = () => {
    if (typeof window !== "undefined") {
      return (
        document?.querySelector<HTMLHeadElement>(".article-title")?.innerText ??
        null
      );
    }

    return null;
  };

  const getArticleIdFromHTML = () => {
    if (typeof window !== "undefined") {
      return (
        document?.querySelector<HTMLHeadElement>(".article-content")?.id ?? null
      );
    }

    return null;
  };

  const isLoggedUser = () => Boolean(Cookies.get("userToken"));

  useEffect(() => {
    const pageType = getPageType(pathname);

    segmentAnalytics.page({
      article_id: getArticleReadableId(pageType),
      article_title: getArticleTitleFromHTML(),
      path: pathname,
      referrer: window.location.href.split("?")[0],
      search: searchParams.get("query") ?? null,
      title: document.title ?? null,
      url: window.location.href,
      isNewHelpcenter: true,
      isLoggedUser: isLoggedUser(),
    });

    eventDispatcher({
      path: pathname,
      page_type: pageType,
      action: "page_view",
      category: "navigation",
      article_id: getArticleIdFromHTML(),
      article_title: getArticleReadableId(pageType),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, searchParams]);

  return null;
}
