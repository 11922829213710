import env from "@/config/env";

export const USER_TYPE_PRIORITY: UserType[] = ["enduser", "partner", "client"];

export const USER_TYPE_MAP: Record<Locale, UserTypes> = {
  "pt-br": {
    enduser: env.userType["pt-br"].enduser,
    partner: env.userType["pt-br"].partner,
    client: env.userType["pt-br"].client,
  },
  "en-us": {
    enduser: env.userType["en-us"].enduser,
    partner: env.userType["en-us"].partner,
    client: env.userType["en-us"].client,
  },
  "en-gb": {
    enduser: env.userType["en-gb"].enduser,
    partner: env.userType["en-gb"].partner,
    client: env.userType["en-gb"].client,
  },
  "it-it": {
    enduser: env.userType["it-it"].enduser,
    partner: env.userType["it-it"].partner,
    client: env.userType["it-it"].client,
  },
  "de-de": {
    enduser: env.userType["de-de"].enduser,
    partner: env.userType["de-de"].partner,
    client: env.userType["de-de"].client,
  },
  "en-ie": {
    enduser: env.userType["en-ie"].enduser,
    partner: env.userType["en-ie"].partner,
    client: env.userType["en-ie"].client,
  },
  "es-es": {
    enduser: env.userType["es-es"].enduser,
    partner: env.userType["es-es"].partner,
    client: env.userType["es-es"].client,
  },
  "es-ar": {
    enduser: env.userType["es-ar"].enduser,
    partner: env.userType["es-ar"].partner,
    client: env.userType["es-ar"].client,
  },
  "es-mx": {
    enduser: env.userType["es-mx"].enduser,
    partner: env.userType["es-mx"].partner,
    client: env.userType["es-mx"].client,
  },
  "es-cl": {
    enduser: env.userType["es-cl"].enduser,
    partner: env.userType["es-cl"].partner,
    client: env.userType["es-cl"].client,
  },
};
