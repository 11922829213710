"use client";

import { hotjar } from "react-hotjar";
import { useEffect } from "react";
import config from "@/config/env";

export default function Initialize() {
  useEffect(() => {
    hotjar.initialize({
      id: Number(config.hotjar.applicationId),
      sv: Number(config.hotjar.version),
    });
  }, []);
  return <></>;
}
