import axios from "axios";

type TGetUserInfos = {
  data: {
    id: string;
    fid: string;
    name: string;
    email: string;
    member?: {
      id: string;
      name: string;
      email: string;
      country_id: string;
      created_at: string;
    };
    clients?: Object[];
    partners?: Object[];
  };
};

export const parseUserId = (getUserInfosResponse: TGetUserInfos): string =>
  getUserInfosResponse.data.id;

export const parseUserType = (getUserInfosResponse: TGetUserInfos): string => {
  const userData = getUserInfosResponse.data;
  const userType = [];
  const isInternalUser = userData.email && ["@gympass", "@wellhub"].some(domain => userData.email.includes(domain));

  if (isInternalUser) {
    userType.push("client,partner,enduser");
  } else {
    if (userData.clients && userData.clients.length > 0) {
      userType.push("client");
    }

    if (userData.partners && userData.partners.length > 0) {
      userType.push("partner");
    }

    if (userData.member) {
      userType.push("enduser");
    }
  }

  return userType.join(",");
};

export const parseUserEmail = (getUserInfosResponse: TGetUserInfos): string =>
  getUserInfosResponse.data.email;

export const parseUserName = (getUserInfosResponse: TGetUserInfos): string =>
  getUserInfosResponse.data.name;

export const getUserInfosRequest = async (
  requesterServiceUrl: string,
  keycloakToken: string
) => {
  const response = await axios.get<TGetUserInfos>(
    `${requesterServiceUrl}/requesters/me`,
    {
      headers: {
        Authorization: "Bearer " + keycloakToken,
        "Content-Type": "application/json",
      },
    }
  );

  return response.data;
};
