"use client";

import {
  EventTrackerProvider,
  SnowplowConfiguration,
  SnowplowEventTracker,
} from "@gympass/event-tracker-snowplow";
import { useEffect } from "react";

export default function SnowplowInitializer({
  ...props
}: React.PropsWithChildren<{ tracker: SnowplowConfiguration }>) {
  const eventTracker = new SnowplowEventTracker(props.tracker);

  useEffect(() => {
    eventTracker.start();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <EventTrackerProvider eventTracker={eventTracker}>
      {props.children}
    </EventTrackerProvider>
  );
}
