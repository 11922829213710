"use client";

import { useEffect } from "react";
import Datadog from "./datadog-init";
import config from "@/config/env";

const Initialize: React.FC = () => {
  useEffect(() => {
    Datadog.init(config.serverEnv);
  }, []);

  return <></>;
};

export default Initialize;