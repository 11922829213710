import { USER_TYPE_MAP, USER_TYPE_PRIORITY } from "@/app/_constants/user-type";
import { removeEmptySpace, splitString } from "@/app/_utils/string-format";

export const selectUserTypeByPriority = (userTypes: UserType[]): UserType => {
  const maxPriority = USER_TYPE_PRIORITY[0];

  if (userTypes.length === 0) {
    return maxPriority;
  }

  if (userTypes.length === 1) {
    return userTypes[0];
  }

  const userTypesSet = new Set<UserType>(userTypes);

  for (const priority of USER_TYPE_PRIORITY) {
    if (userTypesSet.has(priority)) {
      return priority;
    }
  }

  return maxPriority;
};

export const transformUserTypeInArray = (
  userType: Optional<string>
): UserType[] => {
  return (
    userType ? splitString(removeEmptySpace(userType)) : []
  ) as UserType[];
};

export const getUserTypeByCookies = (cookies: Cookies): UserType[] => {
  if (cookies.selectedTabName) {
    return [cookies.selectedTabName];
  } else {
    return transformUserTypeInArray(cookies.userType);
  }
};

export const getActiveTabByUserType = ({
  region,
  userType,
  sessions,
}: {
  region: string;
  userType: UserType;
  sessions:
    | SessionWithPromotedArticles[]
    | AllArticlesPageBuildScreenActionResultData[];
}) => {
  const activeSessionId =
    USER_TYPE_MAP[region.toLocaleLowerCase() as Locale][userType];

  const activeSession = sessions.find(
    (session) => session.id === activeSessionId
  );

  return activeSession ?? sessions[0];
};

export const getTabNameById = (region: Locale, tabId: string): UserType => {
  const regionUserTypeMapping =
    USER_TYPE_MAP[region.toLocaleLowerCase() as Locale];

  for (const [userTypeKey, userTypeId] of Object.entries(
    regionUserTypeMapping
  )) {
    if (userTypeId === tabId) {
      return userTypeKey as UserType;
    }
  }

  return USER_TYPE_PRIORITY[0];
};
