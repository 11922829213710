export const removeEmptySpace = (text: string): string =>
  text.replace(/\s/g, "");

export const splitString = (text: string, delimiter = ","): string[] =>
  text ? text.split(delimiter) : [];

const getUppercaseCountry = (region: string): string =>
  region?.split("-")[1]?.toUpperCase();

export const getPageTitle = (title: string, region: string): string =>
  `${title} - Wellhub ${getUppercaseCountry(region)}`;

export const replaceDoubleCurlyBraceContent = (
  text: string,
  params: { [key: string]: string }
) => {
  const regex = /\{\{(.*?)\}\}/g; // Identifies {{params_name}} in the string

  return text.replace(regex, (match, paramName) => {
    return params[paramName] || match;
  });
};