"use client";
import Cookies from "js-cookie";
import * as requesterService from "@/app/_services/requester";
import { getKeycloakInstance } from "@/app/_services/keycloak";
import { setUserTab } from "@/app/_server-actions/cookies/userTab";
import {
  selectUserTypeByPriority,
  transformUserTypeInArray,
} from "@/utils/userType";
import Script from "next/script";

const getUserFid = (keycloakToken: string) => {
  const userInfo = JSON.parse(atob(keycloakToken.split(".")[1]));
  return userInfo.fid;
};

const setUserInfosOnApplicationStorage = ({
  userId,
  userType,
  userEmail,
  userName,
}: {
  userId: string;
  userType: string;
  userEmail: string;
  userName: string;
}) => {
  Cookies.set("userId", userId);
  Cookies.set("userType", userType);
  Cookies.set("userEmail", userEmail);
  Cookies.set("userName", userName);
  setUserTab(selectUserTypeByPriority(transformUserTypeInArray(userType)));

  localStorage.setItem("userId", userId);
  localStorage.setItem("userType", userType);
  localStorage.setItem("userEmail", userEmail);
  localStorage.setItem("userName", userName);
  localStorage.setItem("userRole", "end_user");
};

const setKeycloakInfosOnApplicationStorage = (keycloakToken: string) => {
  Cookies.set("userToken", keycloakToken);
  Cookies.set("userPlatform", getUserFid(keycloakToken));

  localStorage.setItem("userToken", keycloakToken);
  localStorage.setItem("userPlatform", getUserFid(keycloakToken));
};

const hasErrorMessageOnUrl = () => {
  return !!window.location.search.match(/(\?|&)error_message\=([^&]*)/);
};

const shouldInterruptKeycloakInitialization = (): boolean => {
  return Boolean(localStorage.getItem("userToken"));
};
const initKeycloak = ({
  authUrl,
  clientId,
  requesterServiceUrl,
}: {
  authUrl: string;
  clientId: string;
  requesterServiceUrl: string;
}) => {
  if (shouldInterruptKeycloakInitialization()) {
    return;
  }

  const keycloak = getKeycloakInstance({
    authUrl,
    clientId,
  });

  const options = {
    onLoad: "check-sso",
    responseMode: "query",
    checkLoginIframe: true,
  };

  keycloak.onReady = async (authenticated: boolean) => {
    if (authenticated && !hasErrorMessageOnUrl()) {
      setKeycloakInfosOnApplicationStorage(keycloak.token);

      try {
        const getUserInfosResponse = await requesterService.getUserInfosRequest(
          requesterServiceUrl,
          keycloak.token
        );

        const userId = requesterService.parseUserId(getUserInfosResponse);
        const userType = requesterService.parseUserType(getUserInfosResponse);
        const userEmail = requesterService.parseUserEmail(getUserInfosResponse);
        const userName = requesterService.parseUserName(getUserInfosResponse);

        setUserInfosOnApplicationStorage({
          userId,
          userType,
          userName,
          userEmail,
        });
      } catch (error) {
        console.error(
          "Failed to fetch user infos from requester service: ",
          error
        );
      }
      window.location.reload();
    }
  };

  keycloak.init(options);

  window.keycloakInitialized = true;
};

type TKeycloakInitializer = {
  authUrl: string;
  clientId: string;
  libUrl: string;
  requesterServiceUrl: string;
};

export const KeycloakInitializer: React.FC<TKeycloakInitializer> = ({
  authUrl,
  clientId,
  libUrl,
  requesterServiceUrl,
}) => {
  return (
    <Script
      async
      src={libUrl}
      type="text/javascript"
      onLoad={() =>
        initKeycloak({
          authUrl,
          clientId,
          requesterServiceUrl,
        })
      }
    />
  );
};
