import { datadogRum, RumInitConfiguration } from "@datadog/browser-rum";
import packageJSON from "../../package.json";

const getDatadogConfig = (env: string): RumInitConfiguration => ({
  applicationId: "07a04622-5ad5-4086-86ec-0500b1daef00",
  clientToken: "pubd6ecc43b7f0ecb3eb8f9a9b355ce9844",
  site: "datadoghq.com",
  service: "omnitrix-helpcenter-web",
  env,
  version: packageJSON.version,
  sessionSampleRate: 25,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "mask-user-input",
  // allowedTracingUrls: [
  //   {
  //     match: "https://omnitrix-helpcenter-service.dots.stg.us.gympass.cloud",
  //     propagatorTypes: ["tracecontext"],
  //   },
  // ],
});

export const init = (env: string) => {
  datadogRum.init(getDatadogConfig(env));
  datadogRum.startSessionReplayRecording();
};

export const addError = (error: any, context: any) =>
  datadogRum.addError(error, context);

const Datadog = {
  init,
  addError,
};

export default Datadog;
